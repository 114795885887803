import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  constructor(private router: Router,
              private pageService: PageService) {
    pageService.pageSubject.subscribe(path => this.setMenuText(path))
  }

  showSideMenu = false
  text = "Strona główna"


  ngOnInit(): void {
    /*this.pageService.pageSubject.subscribe(value => {
      console.log(value)
      this.navigate(value);
    })*/
  }

  navigate(path: string): void {
    this.showSideMenu = false;
    if(path.indexOf('robots') < 0) {
      this.router.navigate([path]);
      if(path == 'youtube') {
        window.open('https://www.youtube.com/channel/UCjL9cK79nrN5rn6CTuNJWQA');
      }
    }
  }
  
  setMenuText(path): void {
    if(path == 'strona-glowna') { 
      this.text = "Strona główna"
    }
    if(path == 'moje-papugi') {
      this.text = "Moje papugi"
    }
    if(path == 'karmienie-reczne') {
      this.text = "Karmienie ręczne"
    }
    if(path == 'na-sprzedaz') {
      this.text = "Sprzedam"
    }
    if(path == 'galeria') {
      this.text = "Galeria"
    } 
  }
}
