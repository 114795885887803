<div class="gallery-container">
    <div class="xx">
        <p-galleria [(value)]="photos" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '100%', 'max-height': '100vh'}" [numVisible]="6" [circular]="true" [(activeIndex)]="activeIndex"> 
            <ng-template pTemplate="item" let-item>
                <img [src]="item.image" style="max-height: calc(100vh - 106px); max-width: 100vw;   width: auto;
                height: auto; display: block;"/>
            </ng-template>
            <ng-template *ngIf="showDescriptions" pTemplate="caption" let-item>
                <div class="gallery-label">
                    <h3 style=" color: #ffffff; height: 100%;">{{item.title}}</h3>
                    <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                    <button pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded" (click)="menu.toggle($event)" style="background-color: whitesmoke;"></button>
                </div>
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
                <div class="p-grid p-nogutter p-justify-center" style="width:100px; height:75px; overflow:hidden;">
                    <img [src]="item.image" style="height: 100%; width: 100%;" />
                </div>
            </ng-template>
        </p-galleria>
    </div>
    <div *ngIf="!showDescriptions" style="position: absolute; top: 4px; right: 4px">
        <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
        <button pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded" (click)="menu.toggle($event)" style="background-color: whitesmoke;"></button>
    </div>
</div>