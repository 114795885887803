<div class="topbar">
    <div class="menu-button-container" (click)="showSideMenu = !showSideMenu">
        <div style="margin-top: 5px; line-height: 44px;"><i class="pi pi-bars" style="font-size: 1.8rem;"></i> </div>
        <div style="margin-left: 6px; line-height: 44px;">{{ text }}</div>
    </div>
    <div *ngIf="showSideMenu" class="side-menu slide-in-left">
        <div class="menu-element" (click)="navigate('strona-glowna')">
            Strona główna
        </div>
        <div class="menu-element" (click)="navigate('moje-papugi')">
            Moje papugi
        </div>
        <div class="menu-element" (click)="navigate('karmienie-reczne')">
            Karmienie ręczne
        </div>
        <div class="menu-element" (click)="navigate('na-sprzedaz')">
            Sprzedam
        </div>
        <div class="menu-element" (click)="navigate('galeria')">
            Galeria
        </div>
        <div class="menu-element" (click)="navigate('youtube')">
                <i class="pi pi-youtube" style="font-size: 1.8rem; margin-top: 9px;"></i> 
                <div style="margin-left: 8px; margin-top: 3px;">
                    YouTube
                </div>
        </div>
    </div>
</div>