<div class="my-parrots-container">
    <div class="about-container">
        <div class="parrot-name-container">
            <i class="pi pi-chevron-left" style="font-size: 24px; margin-top: 9px; cursor: pointer;" (click)="changeParrot(-1)"></i> 
            <b class="about-title">{{parrotNames[parrotId]}}</b>
            <i class="pi pi-chevron-right" style="font-size: 24px; margin-top: 9px; cursor: pointer;" (click)="changeParrot(1)"></i> 
        </div>
        <div class="text-about-container">
            <div class="about-text">
                <h3>
                    {{parrotDescriptions[parrotId]}}
                </h3>
            </div>
        </div>
    </div>
    <div class="photo-container">
        <img class="photo" src='{{ getPhoto(photoId) }}' alt="Aleksandretta Chińska">
    </div>
</div>