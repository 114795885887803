import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './pages/main-page/main-page.component'
import { MyParrotsComponent } from './pages/my-parrots/my-parrots.component'
import { PhotoGalleryComponent} from './pages/photo-gallery/photo-gallery.component'
import { ForSaleComponent} from './pages/for-sale/for-sale.component'
import { AppComponent } from './app.component';
import { TopbarComponent } from './pages/topbar/topbar.component';
import { HandFeedingComponent } from './pages/hand-feeding/hand-feeding.component';

const routes: Routes = [
    { path: '', redirectTo: 'strona-glowna', pathMatch: 'full' },
    { path: 'strona-glowna', component: MainPageComponent },
    { path: 'moje-papugi', component: MyParrotsComponent },
    { path: 'karmienie-reczne', component: HandFeedingComponent },
    { path: 'na-sprzedaz', component: ForSaleComponent },
    { path: 'galeria', component: PhotoGalleryComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
