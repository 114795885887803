<div class="main-page-container">
  <h1>Karmienie ręczne papug</h1>
  <h2>Inkubacja</h2>
  <h4>
    Do inkubacji wykorzystuje inkubator Rcom Max 20. Najlepsze rezultaty uzyskuję dla tempetarury inkubacji 37,3°C. 
    Wilgotności uzależniona jest od regionu pochodzenia papug. Dla ptaków pochodzących z bardziej wilogtnych klimatów sprawdzi się większa wilogotność.
    Średnia wilgotność jaką stosuję to 48%. Na trzy dni przed wylęgiem zwiększam wilogotność do 65-75%, aby zmiękczyć skorupkę i ułatwić wyklucie. 
  </h4>
  <div class="gallery">
    <img class="photo" src="assets/inkubacja/1.webp" alt="Jajka papug w inkubatorze">
  </div>
  <h2>Pierwszy tydzień</h2>
  <h4>
    W pierwszym tygodniu po wykluciu temeperaturę utrzymuję około 37°C. 
    Zbyt niska temperatura może prowadzić do zapalenia wola. 
    Ptaki karmione są co 3 godziny, z 1 lub 2 karmieniami nocnymi w zależności od napełnienia wola. 
    Zbyt niska temperatura pożywienia również może prowadzić do problemów z wolem, a należy pamiętać, że ptaki w tym okresie są bardzo wrażliwe.
    Ptaki wyklute w inkubatorze mają mniejszą odporność, ponieważ nie nabywają jej w naturalny sposób od matki, ważne jest więc dbanie o czystość i zachowanie higieny przy karmienu.
  </h4>
  <div class="gallery">
    <img class="photo" src="assets/pierwszy-tydzien/1.webp" alt="Papugi karmione ręcznie">
    <img class="photo" src="assets/pierwszy-tydzien/2.webp" alt="Papugi karmione ręcznie">
    <iframe class="photo"
      src="https://www.youtube.com/embed/8LSSvFFOMV4">
    </iframe>
  </div>
  <h2>Kolejne tygodnie</h2>
  <h4>
    W kolejnych tygodniach papugi zaczynają nabywać odporności i stają się silniejsze. Zmiejszam temparaturę z każdym tygodniem o kilka stponi, tak aby młode papugi po wykształceniu piór, przebywały w temperaturze pokojowej.
    Zmniejsza się również częstotliwość karmienia. Odstępy pomiędzy karmieniami określam na podstawie napełnienia wola, puste wole oznacza porę karmienie.
    Zależenie od gatunku po 4-6 tygodniach karmię papugi trzy razy dziennie: rano, w południe i wieczorem.  
  </h4>
  <div class="gallery">
    <img class="photo" src="assets/kolejne-tygodnie/1.webp" alt="Aleksandretty Większe karmione ręczne">
    <img class="photo" src="assets/kolejne-tygodnie/2.webp" alt="Aleksandretta Większa karmiona ręczne">
    <img class="photo" src="assets/kolejne-tygodnie/3.webp" alt="Aleksandretty Większe karmione ręczne">
    <img class="photo" src="assets/kolejne-tygodnie/4.webp" alt="Papuga królewska / szkarłatka karmiona ręcznie">
  </div>
</div>
