<div class="for-sale-container">
    <p class="no-offers" *ngIf="offers.length==0">Aktualnie brak ofert...</p>
    <div class="offers-container" *ngIf="offers.length > 0">
        <p class="offers-title">Aktualne oferty sprzedaży</p>
        <div class="offer-list-container">
            <div class="offer-container" *ngFor="let offer of offers">
                <div >
                    <p class="offer-title"> {{ offer.title }} </p>
                    <p class="offer-price" >Cena: <b>{{ offer.price }}</b> </p>
                    <p class="offer-description"> {{ offer.description }} </p>
                </div>
                    <div class="offer-price">
                </div>
                <img class="photo" src="{{offer.photoSource}}" alt="Papuga Królewska">
            </div>
        </div>

    </div>

</div>

