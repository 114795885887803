import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PageService {
  public pageSubject: Subject<String> = new Subject();

  constructor() {}

}
