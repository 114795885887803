import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-my-parrots',
  templateUrl: './my-parrots.component.html',
  styleUrls: ['./my-parrots.component.scss']
})
export class MyParrotsComponent implements OnInit {

  parrotId = 0
  photoId = 0

  selectedPhoto = -1

  parrotNames = ['Aleksandretty Chińskie', 
                 'Aleksandretty Większe', 
                 'Amazonki Niebieskoczelne', 
                 'Ary Marakany',
                 'Barwinki Czarnogłowe',
                 'Faliste Helikoptery Japońskie',
                 'Księżniczki Walii',
                 'Papugi Królewskie',
                 'Rozelle Białolice Rubino',
                 'Rozelle Królewskie Niebieskie']
  parrotDescriptions = ['Występują w południowo-zachodnich Chinach. Są masywanie zbudowane. Dorosłe Aleksandretty Chińskie osiągają od 45cm do 51cm długości '+
                        ' od podstawy dzioba do ogona, sam ogon mierzy około 27cm. W środowski naturalny żywią się głównie nasionami, owocami, orzechami, liścmi oraz kwiatami.',
                        'Zasiedlają Azję południową, od Pakistanu, przez Indie, Tajlandię po Wietnam. Dorosłe osobniki osiągają od 50cm do 62cm i ważą od 200g do 250g. ' +
                        'Żywią się owocami, warzywami, nasionami oraz nektarem.',
                        'Występują w lasach deszczowych wschodniej Brazylii, Paragwaju, i północnej Argentyny. Osiagają do 40cm i ważą 400-500g. Amazonki najchętniej '+
                        'żywią się świeżymi owocami, warzywami, kiełkami, ziarnami słonecznika i orzechami ziemnymi.',
                        'W naturalnym środowisku żyją w Brazylii i Paragwaju. Żywią się owocami, orzechami i nasionami. Osiągają około 43 cm, z czego niemal połowę stanowi ogon. Gatunek ten jest bliski wyginięcia',
                        'Zasiedlają północną Brazylię oraz częściwo kraje graniczące z Brazylią od północy i zachodu. Dorosłe osobniki mierzą około 23cm. ',
                        "Mutacja papużki falistej. Wyróżnia się charakterystycznymi piórami przypominającymi śmigła helikoptera.",
                        'Zamieszkuą centralną Australię. Osiągają do 40cm długości. Żywią się przeważnie nasionami.',
                        'Występuję na wschodnim wybrzeżu Australii. Osiągają długość do 43cm oraz ważą do 275g.',
                        'Zamieszkują południowo-wschodnią Australię oraz Tasmanię. Dorosłe osobniki mierzą około 29-34cm i ważą do 120g. Podstawę ich diety stanowi mieszanka ziaren.',
                        'Występuje w południowo-wschodniej Australii oraz Tasmanii. Mierzą od 32cm do 37cm i waża około 130g. W środowisku naturalnym żywią się nasionami, owocami oraz w mniejszym stopniu owadami.']
  photos = ['ac/1.JPG', 'ac/2.JPG', 'ac/3.JPG', 'ac/4.JPG',
            'aw/1.JPG', 'aw/2.JPG', 'aw/3.JPG', 'aw/4.JPG',
            'amazonki/1.JPG', 'amazonki/2.JPG', 'amazonki/3.JPG', 'amazonki/4.JPG',
            'marakany/1.JPG', 'marakany/2.JPG', 'marakany/3.JPG', 'marakany/2.JPG',
            'bc/1.JPG', 'bc/2.JPG', 'bc/3.JPG', 'bc/4.JPG',
            'fhj/1.JPG', 'fhj/2.JPG', 'fhj/3.JPG', 'fhj/4.JPG',
            'kw/1.JPG', 'kw/2.JPG', 'kw/3.JPG', 'kw/4.JPG',
            'pk/1.JPG', 'pk/2.JPG', 'pk/3.JPG', 'pk/4.JPG',
            'rbr/1.JPG', 'rbr/2.JPG', 'rbr/3.JPG', 'rbr/4.JPG',
            'rk/1.JPG', 'rk/2.JPG', 'rk/3.JPG', 'rk/4.JPG']

  constructor(private title: Title,
              private meta: Meta,
              private pageService: PageService) {
    setInterval(() => {
      if(this.photoId < 3)
        this.photoId += 1
      else 
        this.photoId = 0
    }, 6000);
  }

  ngOnInit(): void {
    this.title.setTitle("Papugi w hodowli");
    this.meta.updateTag({ name: 'description', content: 'Zdjęcia i opisy papug w hodowli papug Pajor: Aleksandretty Chińskie, Aleksandretty Wielkie, Amazonki Niebieskoczelne, ' + 
    'Ary Marakany, Faliste Helikoptery, Księżniczki Walii, Papugi Królewskie, Rozelle Białolice Rubino, Rozelle Królewskie Niebieskie' })
    this.pageService.pageSubject.next('moje-papugi')
  }

  changeParrot(step){
    this.parrotId = this.parrotId + step
    if(this.parrotId < 0) this.parrotId = this.parrotNames.length - 1
    else if(this.parrotId >= this.parrotNames.length) this.parrotId = 0
  }

  showPhoto(id){
    this.selectedPhoto = id
  }

  getPhoto(photoId){
    console.log('assets/' + this.photos[(this.parrotId * 4 + photoId)])
    return 'assets/' + this.photos[(this.parrotId * 4 + photoId)]
  }
}
