import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-hand-feeding',
  templateUrl: './hand-feeding.component.html',
  styleUrls: ['./hand-feeding.component.scss']
})
export class HandFeedingComponent implements OnInit {

  constructor(private title: Title,
              private meta: Meta,
              private pageService: PageService) { }

  ngOnInit(): void {
    this.title.setTitle('Karmienie ręczne papug');
    this.meta.updateTag({ name: 'description', content: 'Karmienie ręczne papug, inkubacja jaj papug, temperatura i wilgotność.' })
    this.pageService.pageSubject.next('karmienie-reczne');
  }

}
