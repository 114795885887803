import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { TopbarComponent } from './pages/topbar/topbar.component';
import { MyParrotsComponent } from './pages/my-parrots/my-parrots.component';
import { PhotoGalleryComponent } from './pages/photo-gallery/photo-gallery.component';
import { ForSaleComponent } from './pages/for-sale/for-sale.component';
import { GalleriaModule } from 'primeng/galleria';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { HandFeedingComponent } from './pages/hand-feeding/hand-feeding.component';

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    TopbarComponent,
    MyParrotsComponent,
    PhotoGalleryComponent,
    ForSaleComponent,
    HandFeedingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GalleriaModule,
    ButtonModule,
    MenuModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
