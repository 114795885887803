import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Offer } from 'src/app/model/offer';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-for-sale',
  templateUrl: './for-sale.component.html',
  styleUrls: ['./for-sale.component.scss']
})
export class ForSaleComponent implements OnInit {

  offers: Offer[] = [];

  constructor(private title: Title,
              private meta: Meta,
              private pageService: PageService) { 
  }

  ngOnInit(): void {
    this.title.setTitle("Papugi na sprzedaż")
    this.meta.updateTag({ name: 'description', content: 'Papugi na sprzedaż, ptaki egzotyczne na sprzedaż, ofery sprzedaży papug'})
    this.pageService.pageSubject.next('na-sprzedaz')
  }

}
