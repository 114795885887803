import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MenuItem } from 'primeng/api';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {

  activeIndex = 0;
  showDescriptions = false;

  photos = [
    {image: 'assets/ac/1.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/2.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/3.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/4.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/6.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/7.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/8.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/9.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/10.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/11.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/12.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/ac/13.JPG', title: "Aleksandretty Chińskie"},
    {image: 'assets/aw/1.JPG', title: "Aleksandretty Wielkie"},
    {image: 'assets/aw/2.JPG', title: "Aleksandretty Wielkie"},
    {image: 'assets/aw/3.JPG', title: "Aleksandretty Wielkie"},
    {image: 'assets/aw/4.JPG', title: "Aleksandretty Wielkie"},
    {image: 'assets/amazonki/1.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/2.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/3.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/4.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/5.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/6.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/7.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/8.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/9.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/10.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/11.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/amazonki/12.JPG', title: "Amazonki Niebieskoczelne"},
    {image: 'assets/marakany/1.JPG', title: "Ary Marakany"},
    {image: 'assets/marakany/2.JPG', title: "Ary Marakany"},
    {image: 'assets/marakany/3.JPG', title: "Ary Marakany"},
    {image: 'assets/bc/1.JPG', title: "Barwinki Czarnogłowe"},
    {image: 'assets/bc/2.JPG', title: "Barwinki Czarnogłowe"},
    {image: 'assets/bc/3.JPG', title: "Barwinki Czarnogłowe"},
    {image: 'assets/bc/4.JPG', title: "Barwinki Czarnogłowe"},
    {image: 'assets/bc/5.JPG', title: "Barwinki Czarnogłowe"},
    {image: 'assets/bc/6.JPG', title: "Barwinki Czarnogłowe"},
    {image: 'assets/bc/7.JPG', title: "Barwinki Czarnogłowe"},
    {image: 'assets/bc/8.JPG', title: "Barwinki Czarnogłowe"},
    {image: 'assets/fhj/1.JPG', title: "Faliste Helikoptery Japońskie"},
    {image: 'assets/fhj/2.JPG', title: "Faliste Helikoptery Japońskie"},
    {image: 'assets/fhj/3.JPG', title: "Faliste Helikoptery Japońskie"},
    {image: 'assets/fhj/4.JPG', title: "Faliste Helikoptery Japońskie"},
    {image: 'assets/kw/1.JPG', title: "Księżniczki Walii"},
    {image: 'assets/kw/2.JPG', title: "Księżniczki Walii"},
    {image: 'assets/kw/3.JPG', title: "Księżniczki Walii"},
    {image: 'assets/kw/4.JPG', title: "Księżniczki Walii"},
    {image: 'assets/kw/5.JPG', title: "Księżniczki Walii"},
    {image: 'assets/kw/6.JPG', title: "Księżniczki Walii"},
    {image: 'assets/kw/7.JPG', title: "Księżniczki Walii"},
    {image: 'assets/kw/8.JPG', title: "Księżniczki Walii"},
    {image: 'assets/pk/1.JPG', title: "Papugi Królewskie"},
    {image: 'assets/pk/2.JPG', title: "Papugi Królewskie"},
    {image: 'assets/pk/3.JPG', title: "Papugi Królewskie"},
    {image: 'assets/pk/4.JPG', title: "Papugi Królewskie"},
    {image: 'assets/pk/5.JPG', title: "Papugi Królewskie"},
    {image: 'assets/pk/6.JPG', title: "Papugi Królewskie"},
    {image: 'assets/pk/7.JPG', title: "Papugi Królewskie"},
    {image: 'assets/pk/8.JPG', title: "Papugi Królewskie"},
    {image: 'assets/pk/9.JPG', title: "Papugi Królewskie"},
    {image: 'assets/pk/10.JPG', title: "Papugi Królewskie"},
    {image: 'assets/rbr/1.JPG', title: "Rozelle Białolice Rubino"},
    {image: 'assets/rbr/2.JPG', title: "Rozelle Białolice Rubino"},
    {image: 'assets/rbr/3.JPG', title: "Rozelle Białolice Rubino"},
    {image: 'assets/rbr/4.JPG', title: "Rozelle Białolice Rubino"},
    {image: 'assets/rk/1.JPG', title: "Rozelle Królewskie Niebieskie"},
    {image: 'assets/rk/2.JPG', title: "Rozelle Królewskie Niebieskie"},
    {image: 'assets/rk/3.JPG', title: "Rozelle Królewskie Niebieskie"},
    {image: 'assets/rk/4.JPG', title: "Rozelle Królewskie Niebieskie"},
    {image: 'assets/rk/5.JPG', title: "Rozelle Królewskie Niebieskie"},
    {image: 'assets/rk/6.JPG', title: "Rozelle Królewskie Niebieskie"},
    {image: 'assets/rk/7.JPG', title: "Rozelle Królewskie Niebieskie"},
    {image: 'assets/rk/8.JPG', title: "Rozelle Królewskie Niebieskie"},
  ]
  
  responsiveOptions:any[] = [
    {
      breakpoint: '1366px',
      numVisible: 5
    },
    {
      breakpoint: '1200px',
      numVisible: 4
    },
    {
        breakpoint: '1024px',
        numVisible: 3
    },
    {
        breakpoint: '768px',
        numVisible: 2
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
  ]

  items: MenuItem[] = [{
    label: 'Kategorie',
    items: [
        {label: 'Aleksandretty Chińskie', command: () => this.changePhoto(0)},
        {label: 'Aleksandretty Wielkie', command: () => this.changePhoto(12)},
        {label: 'Amazonki Niebieskoczelne', command: () => this.changePhoto(16)},
        {label: 'Ary Marakany', command: () => this.changePhoto(28)},
        {label: 'Barwinki Czarnogłowe', command: () => this.changePhoto(31)},
        {label: 'Faliste Helikoptery Japońskie', command: () => this.changePhoto(39)},
        {label: 'Księżniczki Walii', command: () => this.changePhoto(43)},
        {label: 'Papugi Królewskie', command: () => this.changePhoto(51)},
        {label: 'Rozelle Białolice Rubino', command: () => this.changePhoto(61)},
        {label: 'Rozelle Królewskie Niebieskie', command: () => this.changePhoto(65)}
    ]
  }]

  constructor(private title: Title,
              private meta: Meta,
              private pageService: PageService) { 
    this.showDescriptions = window.innerWidth >= 768;
  }

  ngOnInit(): void {
    this.title.setTitle("Galeria zdjęć z papugami");
    this.meta.updateTag({ name: 'description', content: 'Galeria zdjęć z papugami: Aleksandretty Chińskie, Aleksandretty Wielkie, Amazonki Niebieskoczelne, ' + 
    'Ary Marakany, Faliste Helikoptery, Księżniczki Walii, Papugi Królewskie, Rozelle Białolice Rubino, Rozelle Królewskie Niebieskie' })
    this.pageService.pageSubject.next('galeria')
  }

  changePhoto(index){
    this.activeIndex = index;
  }

}
