<div class="main-page-container">
    <div class="about-wrapper">
        <div class="about-container">
            <h1 class="about-title">HODOWLA PAPUG</h1>
            <div class="logo-container">
                <img src="assets/logo.webp" style="width: 100%; height: 100%;" alt="Hodowla Papug Pajor logo">
            </div>
            <div class="text-about-container">
                <h3>
                    Hodowlą ptaków egzotycznych zajmuję się od ponad 20 lat. W swojej hodowli posiadam papugi między innymi takich gatunków jak Amazonki Niebieskoczelne, Barwinki Czarnogłowe,
                    Aleksandretty Chińskie, Aleksandretty Większe i Papugi Królewski. Co roku część młodych karmiona jest ręcznie.
                    Hodowla zlokalizowana jest w województwie opolskim. 
                </h3>
            </div>
        </div>
        <div class="main-photo-container">
            <img class="main-image" src="assets/amazonki/14.webp" alt="Papugi Amazonka Niebieskoczelna" style="width: 100%;">
        </div>
    </div>
    <div class="bottom-bar-container">
        <div class="bottom-bar">
            <div style="display: flex;">
                <i class="pi pi-phone" style="font-size: 24px; margin-top: 9px;"></i> 
                <div style="font-size: 24px; margin-left: 10px;">787 864 736</div>
            </div>
            <div style="display: flex;">
                <i class="pi pi-envelope" style="font-size: 24px; margin-top: 9px;"></i> 
                <div style="font-size: 24px; margin-left: 10px;">darekpajor78@wp.pl</div>
            </div>
        </div>
    </div>
</div>